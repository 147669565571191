<template>
    <section>
        <sm_header :title='title' :smHleftEvent='closeWebview'></sm_header>
        <div class="demandItemBox">
          <div v-for="(item, index) in demandData.itsExpenses" :key="index">
            <item-card v-if="item.costType !== '茶歇'" :itemInfo="item" :demandInfo="demandData"></item-card>
            <tea-card v-else :item-info="item"></tea-card>
            <div style="width: 100%;height: .08rem;background-color: #f8f8f8"></div>
          </div>
        </div>
        <div class="fileTitle">
            支持文件
        </div>
        <quote-file :catalog="catalog" :ItemId="demandData.itemId"></quote-file>
    </section>
</template>
<script>
    import itemCard from "./components/itemCard";
    import quoteFile from "./components/quoteFile";
    import teaCard from './components/teaCard'
    export default {
        components: {
            itemCard,
            quoteFile,
            teaCard
        },
        data () {
            return {
                title: '',
                catalog:'',
                titleList: ['酒店会场报价明细','酒店用餐报价明细','酒店住宿报价明细','酒店其他报价明细'],
                fileCatalogList: ['报价文件','报价文件','报价文件','报价文件'],
                demandData: {}
            }
        },
        created () {
            this.demandData = this.$SmartStorage.get('quotePriceItem')
            this.title = this.titleList[this.demandData.itemType - 1]
            this.catalog = this.fileCatalogList[this.demandData.itemType - 1]
        },
        methods: {
            closeWebview () {
                this.$router.go(-1)
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "quotedPriceDetails";
</style>
