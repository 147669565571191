<template>
    <section class="iUploadBox">
        <viewer :images="fileData" class="imgContainer">
            <template>
                <div class="imgBox" :key="i" v-for="(item, i) in fileData">
                    <img class="img" :src="item.url" :title="item.fileName">
                </div>
                <div class="imgBox" v-if="fileData.length % 3 == 2"></div>
            </template>
        </viewer>
    </section>
</template>
<script>
    import Vue from 'vue';
    import Viewer from 'v-viewer';
    import 'viewerjs/dist/viewer.css';
    import {UploadPlug} from 'smart-filer-vue'
    Vue.use(Viewer);
    export default {
        name: 'quoteFile',
        props:['catalog', 'ItemId'],
        data () {
            return {
                fileData: [],
                filterData:{
                    catalog: '',
                    processId: '',
                    supplierId: this.$SmartStorage.get('supplierId')
                },
                uploadPlug: null,
            }
        },
        created() {
            let proposalId = ''
            let eventData = this.$cookie.get("eventData");
            if (eventData) {
                eventData = JSON.parse(eventData)
                proposalId = eventData.proposalId;
            } else {
                proposalId = this.$SmartStorage.get("proposalId");
            }
            this.filterData.processId = proposalId
            this.uploadPlug = new UploadPlug({
                host: process.env.VUE_APP_GATEWAY
            });
            this.queryImg()
        },
        methods: {
            queryImg () {
                this.filterData.catalog = this.catalog
                this.filterData.ItemId = this.ItemId
                this.uploadPlug.queryFile({
                    filterData: {filterData: this.filterData},
                    callback: (data) => {
                        this.fileData = data.content || []
                    }
                })
            },
        }
    }
</script>
<style scoped="scoped" lang="scss">
@import "quoteFile";
</style>
