<template>
    <section>
        <div class="itemInfoBox teaBox">
            <div class="itemTitle">
                {{itemInfo.costType}}
            </div>
            <div class="priceInfo">
                <div>
                    <div>单价</div>
                    <span>{{(itemInfo.price || 0) | currencyFormatter}}</span>
                </div>
                <div>
                    <div>数量</div>
                    <span>{{itemInfo.qty}}</span>
                </div>
                <div>
                    <div>保底人数</div>
                    <span>{{itemInfo.itsExpenseData.minQty}}</span>
                </div>
                <div>
                    <div>报价说明</div>
                    <span>{{itemInfo.itsExpenseData.beyondReason}}</span>
                </div>
            </div>
            <div class="totalAmount">
                小计： {{(itemInfo.amount || 0) | currencyFormatter}}
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'teaCard',
        props: ['itemInfo'],
        data () {
            return {

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "itemCard";
    .teaBox {
        border-radius: .1rem;
    }
</style>
