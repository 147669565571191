<template>
    <section>
        <div class="itemInfoBox">
            <div class="itemTitle">
                {{itemInfo.costType}}
            </div>
            <div class="venueInfo" v-if="['场租'].includes(itemInfo.costType)">
                <div>会场<span>{{itemInfo.itsExpenseData.venueName}}</span></div>
            </div>
            <div class="venueInfo" v-if="['房费'].includes(itemInfo.costType)">
                <div>房间名称<span>{{itemInfo.itsExpenseData.housingName}}</span></div>
            </div>
            <div class="descrInfo">
                <div v-if="['房费'].includes(itemInfo.costType) && !itemInfo.itsExpenseData.isLimitPrice">
                    <div>超参考价原因</div> <span>{{itemInfo.itsExpenseData.beyondCause || '暂无'}}</span>
                </div>
                <div v-if="['场租'].includes(itemInfo.costType) && !itemInfo.itsExpenseData.isLimitPrice">
                    <div>超参考价原因</div> <span>{{itemInfo.itsExpenseData.beyondCause || '暂无'}}</span>
                </div>
                <div>
                    <div>报价说明</div><span> {{itemInfo.descr}}</span>
                </div>
            </div>
            <div class="priceInfo">
                <div v-if="['房费'].includes(itemInfo.costType)">
                    <div>房型</div>
                    <span>{{demandInfo.itemName}}</span>
                </div>
                <div v-if="['房费'].includes(itemInfo.costType)">
                    <div>参考价</div>
                    <span>{{(itemInfo.itsExpenseData.housingPrice || 0) | currencyFormatter}}</span>
                </div>
                <div v-if="['场租'].includes(itemInfo.costType)">
                    <div>参考价</div>
                    <span>{{(itemInfo.itsExpenseData.referencePrice || 0) | currencyFormatter}}/{{itemInfo.itsExpenseData.venueCategory}}</span>
                </div>
                <div>
                    <div>单价</div>
                    <span>{{(itemInfo.price || 0) | currencyFormatter}}</span>
                </div>
                <div>
                    <div v-if="['房费'].includes(itemInfo.costType)">间数</div>
                    <div v-else>数量：</div>
                    <span>{{itemInfo.qty}} {{itemInfo.unit}}</span>
                </div>
                <div v-if="['酒店餐费'].includes(itemInfo.costType)">
                    <div>保底人数</div>
                    <span>{{itemInfo.itsExpenseData.minQty}}</span>
                </div>
                <div v-if="['房费'].includes(itemInfo.costType)">
                    <div>晚数</div>
                    <span>{{itemInfo.qtyExt}}</span>
                </div>
            </div>
            <div class="totalAmount" v-if="['场租'].includes(itemInfo.costType)">
                小计 {{(itemInfo.amount || 0) | currencyFormatter}}
            </div>
            <div class="totalAmount" v-else>
                小计 {{(itemInfo.amount || 0) | currencyFormatter}}
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'itemCard',
        props: {
            'itemInfo': {
                type: Object,
                default () {
                    return {
                        itsExpenseData: {}
                    }
                }
            },
            demandInfo: {
                type: Object,
                default () {
                    return {}
                }
            }
        },
        data () {
            return {

            }
        }
    }
</script>
<style lang="scss" scoped>
@import "itemCard";
</style>
